import Button from "components/Button";
import { IntegrationMetas, getIntegrationMeta } from "data/IntegrationMetas";
import { header, setCookie } from "helpers";
import { useContext, useEffect, useState } from "react";
import AccountContext from "store/account-context";
import { useNavigate } from 'react-router-dom';
import { GAContext } from "store/ga-context";



const ActiveStatus = (connections: any) => {
    let statusText = ""
    if (connections.length > 1) {
        statusText = "Active accounts: " + connections.length
    } else {
        statusText = "Active"
    };

    return (
        <div className="self-end">
            <p className="flex items-center text-sm font-small text-tamahagane"> {statusText} <div className="ml-2 bg-green-500 w-4 h-4 rounded" /></p>
        </div>
    )
}

export const IntegrationListItem = (props: any): JSX.Element => {

    const { integration } = props
    const accountCtx = useContext(AccountContext);
    const GACtx = useContext(GAContext);
    const navigate = useNavigate();
    const [intervalId, setIntervalId] = useState<number | null>(null);
    const [activated, setActivated] = useState(false);
    const [pendingActivation, setPendingActivation] = useState(false);
    const [canActivateMultiple, setCanActivateMultiple] = useState(false)

    useEffect(() => {
        if (integration.connections != null && integration.connections.length > 0) {
            setActivated(true)
        } else if (integration.pending_connections && integration.pending_connections.length > 0) {
            setPendingActivation(true)
            setActivated(false)
        } else {
            setActivated(false)
        }

        if (integration.name === IntegrationMetas.Klaviyo.name) {
            setCanActivateMultiple(true)
        }

        // Stop polling
        return () => {
            if (intervalId) {
                window.clearInterval(intervalId);
            }
        };
    }, []);

    const openAddAccountUrl = () => {
        if (integration.name === IntegrationMetas.Mailchimp.name) {
            pollActivationStatus(IntegrationMetas.Mailchimp)
            window.open(integration["auth-url"], "_blank");
        } else if (integration.name === IntegrationMetas.GoogleAnalytics.name) {
            pollActivationStatus(IntegrationMetas.GoogleAnalytics)
            let integrationToken = accountCtx.integrations.find((integration: any) => integration.integration_id === "googleanalytics").key
            setCookie("gaToken", integrationToken)
            window.open(integration["auth-url"], "_blank")
        } else {
            const integrationItem = accountCtx.integrations.find((integrationItem: IntegrationItem) =>
                getIntegrationMeta(integrationItem.integration_id)?.name === integration.name
            )

            localStorage.setItem('auth-url', JSON.stringify(integration["auth-url"]))
            const encodedString = encodeURIComponent(JSON.stringify(integration.features));
            const params = {
                integrationName: integration.name,
                integrationKey: integrationItem.key,
                features: encodedString
            }
            const queryParams = new URLSearchParams(params).toString();
            window.addEventListener('message', (event) => {
                if (event.data === 'featureActivated') {
                    window.location.reload();
                }
            });
            window.open("/integrations/activation?" + queryParams, "_blank");
        }
    };

    const removeConnection = () => {

        const integrationItem = accountCtx.integrations.find((integrationItem: IntegrationItem) =>
            getIntegrationMeta(integrationItem.integration_id)?.name === integration.name
        )

        const integrationMeta = getIntegrationMeta(integrationItem.integration_id)

        if (integrationMeta != null) {
            const disconnectUrl = integrationMeta.disconnectUrl
            const host = integrationMeta.host
            const headers = new Headers({
                'Authorization': `Bearer ${integrationItem.key}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Host': `${host}`
            });

            fetch(disconnectUrl, {
                method: 'POST',
                headers: headers
            }).then(response => {
                if (response.ok) {
                    setActivated(false)
                    setPendingActivation(false)
                    if (integrationMeta.id === IntegrationMetas.GoogleAnalytics.id) {
                        GACtx?.fetchMeta([integrationItem])
                    }
                }
            })
                .catch(error => {
                    alert("Something went wrong. Please try again.")
                });

        }
    };

    //Poll activation status every 2 seconds in 10 minutes. 
    const pollActivationStatus = (integrationMeta: any) => {
        const integrationItem = accountCtx.integrations.find((integration: IntegrationItem) => integration.integration_id === integrationMeta.id)
        const pollingId = window.setInterval(() => {
            fetch(integrationMeta.metaUrl, header(integrationItem.key))
                .then(response => response.json())
                .then((data: IntegrationMeta) => {
                    if (data.connections != null && data.connections.length > 0) {
                        if (integration.name === IntegrationMetas.Mailchimp.name) {
                            const encodedString = encodeURIComponent(JSON.stringify(integration.features));
                            const params = {
                                integrationName: integration.name,
                                features: encodedString
                            };
                            const queryParams = new URLSearchParams(params).toString();
                            navigate("/integrations/activation?" + queryParams);
                        }
                        window.clearInterval(pollingId)
                        setActivated(true)
                        setPendingActivation(false)
                        if (integrationMeta.id === IntegrationMetas.GoogleAnalytics.id) {
                            GACtx?.fetchMeta([integrationItem])
                        }
                    } else if (data.pending_connections && data.pending_connections.length > 0) {
                        setPendingActivation(true)
                    }
                })
        }, 2000);

        setIntervalId(pollingId)

        // stop polling after 10 minutes
        const timeout = setTimeout(() => {
            window.clearInterval(pollingId!)
        }, 600000);

    }

    return (
        <div className="flex w-full pt-4 pb-4">
            <div>
                <div className="w-40 h-40 p-4 border border-gray col-span-1 flex justify-center items-center rounded-sd">
                    <img className="object-contain p-4" src={integration.image} />
                </div>

            </div>
            <div className="flex flex-col w-full ml-8 mr-8">
                <p className="text-base font-medium font-bold text-tamahagane">{integration.name}</p>
                <div className="pt-4">
                    <p className="font-normal text-base text-tamahagane">{integration["description-en"]}</p>
                </div>
            </div>
            <div className="flex flex-col">
                {activated && <ActiveStatus connections={integration.connections} />}
                {pendingActivation &&
                    <div className="self-end">
                        <div className="flex items-center">
                            <p className="text-tamahagane text-sm">Activation not completed</p>
                            <div className="ml-2 bg-briquette w-4 h-4 rounded" />
                        </div>
                    </div>
                }
                <div className="flex-grow"></div>
                { integration.name == "Shopify" &&
                <div className="flex justify-end">
                    <Button text="Go to the app" bgColor="bg-tamahagane" textColor="white" style="ml-2 px-8 h-12" handleClick={() => { window.open('https://platform.bogio.tech','_blank'); }} />
                </div>
                }
                {
                (integration.name != "Shopify" && !integration.notActivatable) ?
                <div className="flex justify-end">
                    {((activated && !canActivateMultiple) || !activated) && <div className="w-[12rem]" />}
                    {activated && <Button text="Remove" bgColor="bg-briquette" textColor="white" style="px-8 h-12" handleClick={() => { removeConnection() }} />}
                    {!pendingActivation && (!activated || canActivateMultiple) && <Button text="Add account" bgColor="bg-tamahagane" textColor="white" style="ml-2 px-8 h-12" handleClick={() => { openAddAccountUrl(); }} />}
                    {pendingActivation &&
                        <>
                            <Button text="Remove" bgColor="bg-briquette" textColor="white" style="px-8 h-12" handleClick={() => { removeConnection() }} />
                            <Button text="Finish" bgColor="bg-tamahagane" textColor="white" style="ml-2 px-8 h-12" handleClick={() => { openAddAccountUrl(); }} />
                        </>
                    }
                </div>
                : undefined
                }
            </div>
        </div>
    );
};